import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { returnWhatsapp } from '../../../utils/browser';
import { CompanyContext } from '../../../context/company.context';
import { LangContext } from '../../../context/lang.context';
import Layout from '../layout/layout';
import Button from '../button/button';
import { H2, P } from '../typography';
import { Stepper } from '../stepper/stepper';

type GenericProps = {
  h2: string;
  imgSrc?: string;
  p?: string;
  stepperNum?: number;
  callback?: () => any;
  cta?: string;
};

const GenericFailed = ({ h2, imgSrc, p, stepperNum, callback, cta }: GenericProps) => {
  const { getPublicURL } = useContext(CompanyContext);
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETURN_WHATSAPP
        }
      }
    }
  `);
  return (
    <Layout>
      <section className="relative flex flex-col items-center">
        {stepperNum && <Stepper step={stepperNum} />}
        <H2 className={`${stepperNum ? 'mt-[68px]' : 'mt-[60px]'} text-center text-primary`}>{h2}</H2>
      </section>
      <section className="flex w-full flex-col items-center">
        <img
          className="w-2/3"
          alt="Éxito"
          src={getPublicURL(imgSrc || `/theme/assets/images/error/generic-error.svg`)}
        />
        <P
          className="mx-5 mb-5 text-center text-red"
          dangerouslySetInnerHTML={{ __html: p ?? `¡Vaya!<br />Algo no ha funcionado.` }}
        />
      </section>
      <Button
        type="button"
        onClick={() => {
          if (callback) {
            return callback();
          }
          return returnWhatsapp();
        }}
      >
        {cta || t(data).RETURN_WHATSAPP}
      </Button>
    </Layout>
  );
};

export default GenericFailed;
