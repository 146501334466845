import React, { useState, useContext, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Layout from '../../../components/287634/layout/layout';

import { LangContext } from '../../../context/lang.context';
import { useRecaptcha } from '../../../hooks/recaptcha.hook';

import { Pin } from '../../../components/287634/form/input/pin';
import { H2, H4, P } from '../../../components/287634/typography';
import Button from '../../../components/287634/button/button';
import { PinValidation } from '../../../components/287634/form/dynamic-user/sections/pin-validation';
import { isConsecutive, isConsecutiveDown,  isRepeated } from '../../../utils/functions';
import { Loading } from '../../../components/287634/loading';
import Generic from '../../../components/287634/success/generic';
import { returnWhatsapp } from '../../../utils/browser';
import GenericFailed from '@/components/287634/failed/generic-failed';
import { removeNodes } from '../../../utils/dom';

const ChangePINPage = ({ getToken }: PageProps) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              SECURITY_PIN {
                PIN {
                  INFO {
                    MIN_LENGTH
                    NO_CONSECUTIVE
                    NO_REPEAT
                  }
                  REQUIRED
                }
                PIN_CONFIRM {
                  REQUIRED
                  MATCH
                }
              }
            }
          }
        }
      }
    }
  `);

  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const { getRecaptchaToken } = useRecaptcha();
  const token = getToken();

  const schemas = {
    currentPin: Yup.string(),
    pin: Yup.string(),
    confirmPin: Yup.string(),
  };

  schemas.currentPin = Yup.string()
    .min(4, "Ingresa 4 caracteres numéricos")
    .required(t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.REQUIRED);

  schemas.pin = Yup.string()
    .test(
      'not-consecutive',
      'No ingreses números consecutivos',
      (value: string) => (!isConsecutive(value) && !isConsecutiveDown(value))
    )
    .test(
      'not-repeated',
      'No ingreses números repetidos',
      (value: string) => !isRepeated(value)
    )
    .min(4, "Ingresa 4 caracteres numéricos")
    .required(t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.REQUIRED)
    .when('currentPin', (currentPin: string, schema: Yup.StringSchema) => {
      if (currentPin && currentPin.length === 4) {
        return schema.notOneOf([currentPin], 'El nuevo código debe ser diferente del anterior.');
      }
    });

  schemas.confirmPin = Yup.string().when('pin', (pin: string, schema: Yup.StringSchema) => {
    if (pin && pin.length) {
      return schema
        .oneOf([pin], t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN_CONFIRM.MATCH)
        .min(4, "Ingresa 4 caracteres numéricos")
        .required('Ingresa de nuevo el código de seguridad');
    }
    return schema;
  });

  const formik = useFormik({
    initialValues: { currentPin: '', pin: '', confirmPin: '' },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape(schemas),
    onSubmit(values: { currentPin: string; pin: string; confirmPin: string }) {
      handleSubmit(values.currentPin, values.pin);
    },
  });

  const handleSubmit = async (currentPin: string, pin: string) => {
    const recaptchaToken = await getRecaptchaToken();
    axios
      .post(`/apiweb/customer/changepin?token=${token}`, {
        old_password: currentPin,
        password: pin,
        recaptcha: {
          response: recaptchaToken,
        },
      })
      .then(response => response.data)
      .then(({ action }) => {
        if (action === 'finished') {
          setStatus('success');
        } else {
          throw new Error('Response not valid.');
        }
      })
      .catch(error => {
        console.log(error);
        setStatus('error');
      });
  };

  useEffect(() => {
    return () => {
      removeNodes(['#grecaptcha', '.grecaptcha-badge']);
    };
  }, []);

  if (formik.isSubmitting && status === 'idle') {
    return <Loading />;
  }

  if (status === 'success') {
    return (
      <Generic
        h1={`¡Fiu! ¡Fiuuu!<br />Código de seguridad modificado con éxito. `}
        h2="Código de seguridad"
        imgSrc="/theme/assets/images/status/pin-success.svg"
        callback={() => returnWhatsapp()}
        cta="Volver a WhatsApp"
      />
    );
  }

  if (status === 'error') {
    return (
      <GenericFailed
        h2="Código de seguridad"
        p="¡Oh, no! Ha habido un error y no se ha modificado tu código de seguridad."
        callback={() => returnWhatsapp()}
        cta="Volver a WhatsApp"
      />
    );
  }

  return (
    <Layout>
      <H2 className="mx-auto mb-12 mt-[68px] px-5 text-center text-primary">Cambia tu código de seguridad</H2>
      <P className="text-base leading-tight mx-5 mb-10 text-center text-primary">
      Crea el nuevo código de seguridad con el que garantizar y proteger tus operaciones y la información de tu perfil.
      </P>
      <form className="flex flex-auto flex-col items-center justify-between" onSubmit={formik.handleSubmit} noValidate>
        <section className="mb-5 flex flex-col items-center">
          <label className="mb-2 text-lg font-medium text-primary">Introduce tu código de seguridad actual</label>
          <Pin
            value={formik.values.currentPin}
            onChange={formik.handleChange('currentPin')}
            warning={formik.errors.currentPin}
          />
        </section>
        <section className="flex flex-col items-center">
          <label className="mb-2 text-lg font-medium text-primary">Introduce tu nuevo código de seguridad</label>
          <Pin value={formik.values?.pin} onChange={formik.handleChange('pin')} warning={formik.errors.pin} />

          <label className="mb-2 mt-5 text-lg font-medium text-primary">Repite tu nuevo código de seguridad</label>
          <Pin
            value={formik.values?.confirmPin}
            onChange={formik.handleChange('confirmPin')}
            warning={formik.errors.confirmPin}
          />

          <PinValidation pinValue={formik?.values.pin} />
          <H4 className="mb-4 mt-12 text-gray">Por seguridad, no uses tu año de nacimiento.</H4>
          <Button type="submit" disabled={formik.isSubmitting} className="mt-15">
            Guardar
          </Button>
        </section>
      </form>
    </Layout>
  );
};

export default ChangePINPage;
